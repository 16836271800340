import { classNames } from '../../utils/classNames';
import { removeMarkdown } from '../../utils/removeMarkdown';

import Markdown from './Markdown';
import ExpandButton from './ExpandButton';

const MobileTemplateCard = ({
	isExpanded,
	sectionName,
	contactContent,
	isContactSection,
	image,
	header,
	isLongText,
	displayedText,
	toggleCard,
	handleOpenCardPopup,
}) => {
	const renderHeading = (header) => {
		const words = removeMarkdown(header).split(' ');

		return words.length > 1 ? (
			<>
				<span className='block'>{words[0]}</span>
				<span className='block'>{words.slice(1).join(' ')}</span>
			</>
		) : (
			<span>{words[0]}</span>
		);
	};

	return (
		<div
			className={classNames(
				'flex flex-col gap-[22px]',
				'rounded-[26px] p-[18px]',
				'shadow-cardShadow bg-backgroundColor-secondary'
			)}
		>
			<div className='flex items-center gap-[22px]'>
				{isContactSection ? (
					<img
						src={contactContent.img}
						alt=''
						className={classNames(
							'rounded-[11px] object-cover',
							'max-w-[140px] xs:max-w-[50%] w-full h-[90px] xs:h-[120px]'
						)}
					/>
				) : (
					image && (
						<img
							src={image}
							alt=''
							className={classNames(
								'rounded-[11px]',
								sectionName === 'Partners' ? 'object-center' : 'object-cover',
								'max-w-[140px] xs:max-w-[50%] w-full h-[90px] xs:h-[120px]'
							)}
						/>
					)
				)}

				<h3 className='font-bold text-[18px] xs:text-[22px] leading-[24px] xs:leading-[28px]'>
					{isContactSection ? renderHeading(contactContent.header) : renderHeading(header)}
				</h3>
			</div>

			{isExpanded && (
				<>
					{isContactSection ? (
						<>
							<p className='font-medium text-sm leading-[25px] whitespace-normal'>{contactContent.beforeText}</p>

							<ul className='flex flex-col gap-2 my-3'>
								{contactContent.contacts.map(({ icon, link, name, text }, index) => (
									<li key={index}>
										<a
											href={link}
											target={name !== 'Phone' ? '_blank' : '_self'}
											rel='noopener noreferrer'
											className='text-accent flex items-center gap-2'
										>
											<img src={icon} alt={`${name} Icon`} className='w-[14px] h-[14px]' />

											<div className='flex items-center gap-1'>
												<span className='font-semibold text-sm leading-[25px]'>{name}:</span>
												<span>{text}</span>
											</div>
										</a>
									</li>
								))}
							</ul>

							<p className='font-medium text-sm'>{contactContent.afterText}</p>
						</>
					) : (
						<span className='font-medium text-sm leading-[25px] whitespace-normal'>
							<Markdown displayedText={displayedText} />
						</span>
					)}
				</>
			)}

			<div className='flex items-center justify-between'>
				{sectionName && (
					<div className='flex items-center gap-[6px]'>
						<div className='w-[17px] h-[17px] rounded-full bg-accentGradient' />
						<span className='text-[12px] leading-[16px]'>{sectionName}</span>
					</div>
				)}

				<ExpandButton
					header={header}
					sectionName={sectionName}
					isLongText={isLongText}
					handleOpenCardPopup={handleOpenCardPopup}
					isExpanded={isExpanded}
					toggleCard={toggleCard}
				/>
			</div>
		</div>
	);
};

export default MobileTemplateCard;
