import { classNames } from '../../utils/classNames';
import { useAppContext } from '../../context/AppContextProvider';

import FormComponent from '../form/FormComponent';
import arrowIcon from '../../assets/white-arrow-icon.svg';
import sloganIcon from '../../assets/hero/slogan-icon.svg';

const CustomizeExperienceCard = ({ mode }) => {
	const { scrollStartRef } = useAppContext();

	const onStartJourney = () => {
		if (scrollStartRef.current) {
			scrollStartRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		}
	};

	return (
		<div
			className={classNames(
				'w-full flex flex-col items-center overflow-hidden',
				'relative mx-auto rounded-[32px] transition duration-300 ease-in-out z-20',

				'max-h-[515px] md:min-h-[600px]',
				'max-w-[876px] w-full',
				'lg:max-w-[clamp(600px,60%,876px)]',
				'xl:max-w-[clamp(650px,65%,876px)]',
				'xxl:max-w-[clamp(700px,80%,876px)]'
			)}
		>
			<div className='w-full flex-1 flex flex-col items-center p-8 text-center bg-backgroundColor-secondary'>
				<img src={sloganIcon} alt='slogan' className='h-[18px] w-[18px]' />

				<h2
					className={classNames(
						'font-extrabold text-[30px] sm:text-4xl md:text-[40px] lg:text-5xl xxl:text-[55px]',
						'leading-[40px] md:leading-[50px] xxl:leading-[57px]',
						'my-[22px] bg-gradient text-gradient z-20'
					)}
				>
					<span className='font-light'>The first</span> <br /> Interactive website
				</h2>

				<span
					className={classNames(
						'text-[15px] sm:text-[17px] lg:text-[18px]',
						'leading-[20px] xl:leading-[28px] font-medium z-20'
					)}
				>
					for Personalized Exploration
				</span>
			</div>

			<div
				className={classNames(
					'flex flex-col items-center justify-start',
					'relative w-full h-[438px] p-2 md:p-4',
					'bg-customize-gradient bg-cover bg-center'
				)}
			>
				<p
					className={classNames(
						'max-w-[275px] sm:max-w-none w-full my-4 md:my-6 z-20',
						'text-[20px] sm:text-[24px] md:text-[26px] xxl:text-[30px]',
						'leading-[26px] sm:leading-[34px] xxl:leading-[38px]',
						'font-medium text-center bg-accentGradient text-gradient'
					)}
				>
					Continue exploring <br className='xs:hidden' /> or ask your question
				</p>

				<FormComponent mode='card' />

				{mode !== 'popup' && (
					<button
						onClick={onStartJourney}
						className={classNames(
							'flex items-center gap-3',
							'w-[211px] h-12 rounded-[38px] pl-8 pr-5 py-3 mt-10',
							'bg-backgroundColor-tertiary text-backgroundColor-secondary z-20'
						)}
					>
						<span className='text-[16px] leading-[22px]'>Start your journey</span>
						<img src={arrowIcon} alt='' className='w-[14px] h-[15px]' />
					</button>
				)}

				<div
					className={classNames(
						'max-w-[337px] w-full h-[313px]',
						'bg-customize-image bg-cover bg-center',
						'z-10 absolute bottom-0 left-1/2 -translate-x-1/2'
					)}
				/>
			</div>
		</div>
	);
};

export default CustomizeExperienceCard;
