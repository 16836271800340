import { useState } from 'react';
import { getTranscription } from '../../axios/API';
import { classNames } from '../../utils/classNames';

import { BsSquareFill } from 'react-icons/bs';
import CircleButton from '../buttons/CircleButton';
import voiceIcon from '../../assets/input/voice-icon.svg';
import '../../styles/pulse.scss';

const VoiceRequest = ({ handleSubmit, formLoading, recordingLoading, setRecordingLoading }) => {
	const [isRecording, setIsRecording] = useState(false);
	const [mediaRecorder, setMediaRecorder] = useState(null);

	const window_navigator = window.navigator;

	const handleRecordingStart = async () => {
		try {
			const stream = await window_navigator.mediaDevices.getUserMedia({ audio: true });
			const recorder = new MediaRecorder(stream);
			const audioChunks = [];

			recorder.ondataavailable = (event) => {
				audioChunks.push(event.data);
			};

			recorder.onstop = async () => {
				stream.getTracks().forEach((track) => track.stop());

				const audioBlob = new Blob(audioChunks, { type: 'audio/mp4' });
				const formData = new FormData();
				formData.append('file', audioBlob, 'audio.mp4');

				setRecordingLoading(true);

				try {
					const { data } = await getTranscription(formData);
					handleSubmit({ preventDefault: () => {} }, data.transcription);
				} catch (error) {
					console.error(error);
				} finally {
					setRecordingLoading(false);
					setIsRecording(false);
				}
			};

			recorder.start();
			setMediaRecorder(recorder);
			setIsRecording(true);
		} catch (error) {
			console.error('Error accessing microphone:', error);
		}
	};

	const handleRecordingStop = (event) => {
		event.preventDefault();

		if (mediaRecorder) {
			mediaRecorder.stop();
		}
	};

	return isRecording && !recordingLoading ? (
		<button
			onClick={handleRecordingStop}
			aria-label='Stop recording'
			className={classNames(
				'h-8 md:h-11 w-8 md:w-11 aspect-square',
				'flex items-center justify-center',
				'rounded-full bg-button-accent shadow-voiceShadow',
				'pulsate-border recording z-20'
			)}
		>
			<div className='pulse' />
			<BsSquareFill />
		</button>
	) : (
		<CircleButton
			iconSRC={voiceIcon}
			iconALT='voice input'
			className='bg-button-accent shadow-voiceShadow z-20'
			handleClick={handleRecordingStart}
			disabled={formLoading}
			ariaLabel='Start recording'
		/>
	);
};

export default VoiceRequest;
