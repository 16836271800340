import { classNames } from '../../utils/classNames';
import chevronIcon from '../../assets/templates-cards/chevron-icon.svg';

const ExpandButton = ({ header, sectionName, isLongText, handleOpenCardPopup, isExpanded, toggleCard }) => {
	return isLongText ? (
		<button
			className={classNames(
				'w-[107px] h-[30px]',
				'text-xs text-input-placeholder font-medium',
				'ml-auto rounded-[30px] bg-[#E9F0FF]'
			)}
			onClick={() => handleOpenCardPopup(header)}
		>
			See More
		</button>
	) : (
		<button
			onClick={() => toggleCard(header)}
			aria-label={isExpanded ? 'Collapse card' : 'Expand card'}
			className={classNames(!sectionName ? 'ml-auto' : '')}
		>
			<img
				src={chevronIcon}
				alt={isExpanded ? 'Collapse icon' : 'Expand icon'}
				className={classNames(isExpanded ? 'rotate-180' : '', 'h-[30px] w-[30px]')}
			/>
		</button>
	);
};

export default ExpandButton;
