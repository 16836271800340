import { forwardRef, useState, useMemo } from 'react';

import useMediaQuery from '../../hooks/useMediaQuery';
import { contactContent } from '../../constants/contact';
import { removeMarkdown } from '../../utils/removeMarkdown';
import { getRandomInteger, truncateText } from '../../utils/truncateText';

import CardPopup from '../popup/CardPopup';
import MobileTemplateCard from './MobileTemplateCard';
import DesktopTemplateCard from './DesktopTemplateCard';

const TemplateCardWrapper = forwardRef(
	({ setContentActiveItems, image, header, text, maxWidth, isFirst, isLast, index, sectionName }, ref) => {
		const [isExpanded, setIsExpanded] = useState(false);
		const [isCardPopupOpen, setIsCardPopupOpen] = useState(false);

		const isMobile = useMediaQuery('(max-width: 576px)');

		const handleHighlighContentItem = (header) => {
			setContentActiveItems((prev) => {
				if (!prev.includes(header)) return [...prev, header];

				return prev;
			});
		};

		const toggleCard = (header) => {
			setIsExpanded((prev) => !prev);
			handleHighlighContentItem(header);
		};

		const isContactSection = sectionName === 'Contacts' || sectionName === 'Contact' || header.includes('Contact');
		const isLongText = text.length >= 1000;

		const truncatedText = useMemo(() => {
			const maxTextLength = isFirst ? 350 : getRandomInteger(200, 300);
			return isContactSection ? text : truncateText(removeMarkdown(text), maxTextLength);
		}, [text, isFirst, isContactSection]);

		const shouldShowImage = isExpanded || index % 10 !== 0;
		const shouldShowText = isExpanded || index % 10 !== 1;
		const displayedText = isExpanded ? text : truncatedText;

		const handleOpenCardPopup = (header) => {
			setIsCardPopupOpen(true);
			handleHighlighContentItem(header);
		};

		return (
			<>
				{isMobile && !isFirst ? (
					<MobileTemplateCard
						isExpanded={isExpanded}
						sectionName={sectionName}
						contactContent={contactContent}
						isContactSection={isContactSection}
						image={image}
						header={header}
						isLongText={isLongText}
						displayedText={displayedText}
						toggleCard={toggleCard}
						handleOpenCardPopup={handleOpenCardPopup}
					/>
				) : (
					<DesktopTemplateCard
						ref={ref}
						isLast={isLast}
						isFirst={isFirst}
						maxWidth={maxWidth}
						isExpanded={isExpanded}
						sectionName={sectionName}
						contactContent={contactContent}
						isContactSection={isContactSection}
						shouldShowText={shouldShowText}
						shouldShowImage={shouldShowImage}
						image={image}
						header={header}
						text={text}
						isLongText={isLongText}
						displayedText={displayedText}
						toggleCard={toggleCard}
						handleOpenCardPopup={handleOpenCardPopup}
					/>
				)}

				<CardPopup
					isOpen={isCardPopupOpen}
					handleClose={() => setIsCardPopupOpen(false)}
					cardContent={{ image, header, text }}
					zIndex='z-[80]'
					className={[
						'fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
						'max-w-[849px] w-full px-2 md:px-3 lg:px-4 z-[81]',
					]}
				/>
			</>
		);
	}
);

export default TemplateCardWrapper;
