import { getArticles } from '../../axios/API';
import { classNames } from '../../utils/classNames';
import { useAppContext } from '../../context/AppContextProvider';

import arrowIcon from '../../assets/templates-cards/diagonal-arrow.svg';

const RelatedQuestions = ({ questions, sectionLoading }) => {
	const {
		scrollToBottom,
		setContentList,
		handleChangeSectionRefs,
		formLoading,
		recordingLoading,
		questionLoading,
		setQuestionLoading,
		updateRequestRecord,
	} = useAppContext();

	const fetchQuestionsData = async (question, sectionRef) => {
		if (sectionLoading || formLoading || questionLoading || recordingLoading) return;

		setQuestionLoading(true);
		updateRequestRecord(question);

		try {
			const { data } = await getArticles(question);

			setContentList((prev) => [
				...prev,
				{
					type: 'questions',
					sectionRef: sectionRef,

					// sectionName: NAVIGATION.find((element) => element.value === value).label,
					// sectionId: NAVIGATION.find((element) => element.value === value).value,

					items: data.block.items,
					questions: data.questions,

					// nextPage: pageParam + 1,
					// hasMore: availableSectionsList.length > 1,
				},
			]);
		} catch (error) {
			console.error(error);
		} finally {
			setQuestionLoading(false);
			setTimeout(() => {
				if (sectionRef.current) {
					sectionRef.current.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
					});
				}
			}, 100);
		}
	};

	const handleQuestionClick = (question) => {
		const sectionRef = handleChangeSectionRefs(question);

		scrollToBottom();
		fetchQuestionsData(question, sectionRef);
	};

	return Array.isArray(questions) && questions.length > 0 ? (
		<div className='flex flex-col gap-4 mt-16 max-w-[522px] mx-4 md:mx-0'>
			<h4 className='font-bold text-[22px] leading-[30px]'>Related Questions</h4>

			<ul className='flex flex-col gap-2 mt-6'>
				{questions.map((question, index) => (
					<li
						key={`${question}-${index}`}
						className={classNames(
							'flex items-center justify-between',
							'py-2 md:py-[2px] pr-[13px] pl-6 rounded-[41px]',
							'border border-white bg-white/40 cursor-pointer'
						)}
						onClick={() => handleQuestionClick(question)}
					>
						<span className='font-medium text-sm leading-[19px]'>{question}</span>

						<button
							className='h-[39px] w-[39px] flex items-center justify-center'
							aria-label='Send request for related question'
						>
							<img src={arrowIcon} alt='arrow' className='w-3 h-3' />
						</button>
					</li>
				))}
			</ul>
		</div>
	) : null;
};

export default RelatedQuestions;
